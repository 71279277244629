import { useGLTF } from "@react-three/drei";
import { useRef, useEffect } from "react";

export default function HatenaBlock(props) {
  const group = useRef();
  // const { nodes, materials } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/star/model.gltf')
  // const { scene } = useThree()
  const { scene } = useGLTF("./QuestionBlock.glb");

  function handleClick() {
    console.log("clicked");
  }

  useEffect(() => {
    // GLTFLoader('QuestionBlock.glb').then((glb) => {
    //     glb.scale.set(0.1,0.1,0.1)
    //     scene.add(glb)
    // })
  }, []);
  return (
    <group ref={group} {...props} dispose={null}>
      <primitive onClick={handleClick} object={scene} />
    </group>
  );
}

useGLTF.preload("./QuestionBlock.glb");
